import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function BasicPaper({width,title,children,height=400}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: width,
          height: height,
          borderRadius: 2,
        },
      }}
    >
     <Paper elevation={3} >
        <h1 className='pl-6 pt-6 font-semibold lg:text-lg md:text-base text-xs'>{title}</h1>
        {children}
     </Paper>
    </Box>
  );
}
