import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const BasicCard = props => {
  return(
    <Card elevation={3} sx={{ borderRadius: 2 }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {props.subTitle}
        </Typography>

        <Typography variant="h5" component="div" style={valStyle}>
          {props.content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BasicCard;